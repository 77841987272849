import { DirectusUser, readMe, withToken } from '@directus/sdk';
import type { NextAuthOptions, Session } from 'next-auth';

import CredentialsProvider from 'next-auth/providers/credentials';
import { JWT } from 'next-auth/jwt';
import { directusFrontendAuth } from '@/utils/directus';
import env from '@/env';

export const options: NextAuthOptions = {
    providers: [
        CredentialsProvider({
            name: 'Credentials',
            credentials: {
                email: { label: 'Email', type: 'email', placeholder: 'Enter your Email...' },
                password: { label: 'Password', type: 'password' },
            },
            async authorize(credentials, req) {
                if (!credentials?.email || !credentials?.password) {
                    return null; // Let the JWT callback handle token revalidation
                }

                const res = await fetch(`${env.urls.backend}/auth/login`, {
                    method: 'POST',
                    body: JSON.stringify({
                        email: credentials.email,
                        password: credentials.password
                    }),
                    headers: { 'Content-Type': 'application/json' },
                });
                const user = await res.json();
                // console.log({ UserInAuthorize: user, payload: { email: credentials.email, password: credentials.password } });
                if (!res.ok && user) {
                    throw new Error('Email address or password is invalid');
                }
                if (res.ok && user) {
                    return user;
                }

                return null;
            },
        }),
    ],
    secret: process.env.NEXTAUTH_SECRET,
    pages: {
        signIn: '/login',
    },
    callbacks: {
        async jwt({ token, user, account }: { token: JWT, user: any, account: any }) {
            // console.log('JWT Callback:', { token, user, account });

            if (account && user) {
                const userData = await directusFrontendAuth.request<Partial<DirectusUser<"directus_users">>>(
                    withToken(
                        user.data.access_token as string,
                        readMe({
                            fields: ['*'],
                        })
                    )
                );

                const ret = {
                    ...token,
                    accessToken: user.data.access_token,
                    refreshToken: user.data.refresh_token,
                    accessTokenExpires: Date.now() + user.data.expires * 1000,
                    user: userData
                };
                // console.log({userDataInJWT1: {token, userData}});
                // console.log({userDataInJWT1: ret});
                return ret;
            }

            // if (Date.now() < token.accessTokenExpires) {
            //     return token;
            // }



            // if no valid next-auth session present yet check for a directus session
            try {
                // console.log('Checking token validity...', { token: token.accessToken });
                const userData = await directusFrontendAuth.request<Partial<DirectusUser<"directus_users">>>(
                    withToken(
                        token.accessToken as string,
                        readMe({
                            fields: ['*'],
                        })
                    )
                );

                // console.log({ userDataInJWT2: { token, userData } });
                // console.log({ userDataInJWT2: {
                //     ...token,
                //     user: userData
                // }})
                // If we get here without an error, the token is still valid
                return {
                    ...token,
                    user: userData
                };
            } catch (error: any) {
                const isTokenExpired = (error?.errors as any[]).some((e) => e.extensions.code === 'TOKEN_EXPIRED')

                if (isTokenExpired) {
                    console.log('Token is expired, refreshing...');
                    const refreshedTokens = await refreshAccessToken(token.refreshToken as string);

                    return {
                        ...token,
                        accessToken: refreshedTokens.accessToken,
                        refreshToken: refreshedTokens.refreshToken,
                        accessTokenExpires: refreshedTokens.accessTokenExpires,
                    };
                }

                console.error('Error verifying token:', JSON.stringify(error, null, 2));

                // Token is no longer valid
                return { ...token, accessToken: null, refreshToken: null, user: null };
            }
        },
        async session({ session, token }: { session: Session; token: any }) {
            // console.log('Session Callback:', { session, token });

            // Add accessToken and refreshToken to the session user object
            // console.log({ optionsSession: { user: token.user, token: token, session } });
            let user;
            if (token.user) {
                user = token.user;
                user.accessToken = token.accessToken;
                user.refreshToken = token.refreshToken;
            } else {
                user = null;
            }
            session.accessToken = token.accessToken as string;
            session.accessTokenExpires = token.accessTokenExpires as number;
            session.user = user;
            // console.log({'SESSION CALLBACK USER: ': session.user});
            // console.log({'SESSION CALLBACK RETURN: ': session});
            return session;
        },
    },
    session: {
        strategy: 'jwt',
    }
};

async function refreshAccessToken(refreshToken: string) {
    try {
        console.log("REFRESHING TOKEN");
        // TODO check why this ends in an endless loop
        // const directusClient = await getDirectusFrontendAuth();
        // const refreshData = await directusClient.refresh();
        // return {
        //     accessToken: refreshData.access_token,
        //     refreshToken: refreshData.refresh_token,
        //     accessTokenExpires: Date.now() + refreshData.expires_at! * 1000,
        // };

        const response = await fetch(`${env.urls.backend}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh_token: refreshToken,
            }),
        });

        const refreshedTokens = await response.json();

        if (!response.ok) {
            throw refreshedTokens;
        }

        return {
            accessToken: refreshedTokens.data.access_token,
            refreshToken: refreshedTokens.data.refresh_token,
            accessTokenExpires: Date.now() + refreshedTokens.data.expires * 1000,
        };
    } catch (error) {
        console.error('Error refreshing access token', error);
        return {
            accessToken: null,
            refreshToken: null,
            accessTokenExpires: 0,
            error: 'RefreshAccessTokenError',
        };
    }
}